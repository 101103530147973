import React, {useEffect, useState} from 'react';

import {Customer, Message} from '../../models/types';
import {Servicer} from '../../models/types';
import MessageService from "../../services/message";
import moment from 'moment/moment';
import CustomerService from "../../services/customer";
import StateService from "../../services/state";

type RoomProps = {
  servicers: Servicer[]
  setRoom: Function
  setKeyword: Function
}

type LatestMsg = {
  customer_code: string
  message: Message
};

const RoomAside = (props: RoomProps) => {

  const { servicers, setRoom, setKeyword } = props

  const [inputValue, setInputValue] = useState('');

  const [latestMsgList, setLatestMsgList] = useState<LatestMsg[]>([]);

  // useEffect(() => {
  //   customers.map((customer, index) => {
  //     const reqGetMessage = {
  //       customer_code: customer.code,
  //       servicer_code: servicer.code,
  //       offset: 0,
  //       limit: 1,
  //     };
  //
  //     MessageService.get_msg_list(reqGetMessage).then((e) => {
  //       // console.log('Success:', e.data);
  //       if (e.data.count > 0) {
  //         const latestMsg = {
  //           customer_code: customer.code,
  //           message: e.data.data[0]
  //         }
  //         setLatestMsgList([...latestMsgList, latestMsg]);
  //       }
  //     }).catch((error) => {
  //       console.error('Error:', error);
  //     });
  //   })
  // }, [servicer, customers]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setKeyword(inputValue);
      // alert(inputValue);
      // setInputValue(event.target.value);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    setKeyword(inputValue);
    // alert('Input value: ' + inputValue);
  };

  const upgradeService = (servicer_code: string, customer_code: string) => {
    const reqCustomerUpgradeService = {
      open_kfid: servicer_code,
      external_userid: customer_code,
    };

    CustomerService.upgrade_service(reqCustomerUpgradeService).then((e) => {
      console.log('Success:', e.data);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  const setServiceState = (servicer_code: string, customer_code: string) => {
    const reqSetServiceState = {
      open_kfid: servicer_code,
      external_userid: customer_code,
      service_state: 4, // 关闭会话
    };

    StateService.set_service_state(reqSetServiceState).then((e) => {
      console.log('Success:', e.data);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  return (
    <div className="tab-pane fade h-100 show active" id="tab-room" role="tabpanel">
      <div className="d-flex flex-column h-100 position-relative">
        <div className="hide-scrollbar">

          <div className="container py-8">
            <div className="mb-8">
              <h2 className="fw-bold m-0">客户消息</h2>
            </div>

            <div className="mb-6">
              <form action="#">
                <div className="input-group">
                  <div
                    className="input-group-text"
                    onClick={handleButtonClick}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="icon icon-lg">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                           fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                           strokeLinejoin="round" className="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </div>
                  </div>

                  <input
                    type="text"
                    className="form-control form-control-lg ps-0"
                    placeholder="搜索客户消息"
                    aria-label="正在搜索..."
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </form>
            </div>

            <div className="card-list">
              {servicers?.map((servicer, _) => (
                <>
                  <div className="my-5">
                    <small className="text-uppercase text-muted">{servicer.name}({servicer.rooms.length})</small>
                  </div>
                  {servicer.rooms.sort((a, b) => (b.latest_msg_time - a.latest_msg_time) || (b.latest_rep_time - a.latest_rep_time)).map((room, index) => (
                    <div
                      key={index}
                      className="card border-0 text-reset"
                    >
                      <div className="card-body">
                        <div className="row gx-5">
                          {/*<div className="col-auto">*/}
                          {/*  <div className="avatar avatar-online">*/}
                          {/*    <span className="avatar-text">M</span>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                          <div
                            className="col-auto"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {setRoom(room)}}
                          >
                            <div className={(room.status_room === 3 && "avatar avatar-online") || (room.status_room === 6 && "avatar avatar-offline") || "avatar avatar-leave"}>
                              {
                                (room.customer.avatar === "")
                                  ?
                                  <span className="avatar-text">客户</span>
                                  :
                                  <img className="avatar-img" src={room.customer.avatar} alt={room.customer.name}/>
                              }
                            </div>
                          </div>

                          <div
                            className="col"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {setRoom(room)}}
                          >
                            <div className="d-flex align-items-center mb-3">
                              <h5 className="me-auto mb-0">{room.customer.real_name !== '' ? room.customer.real_name : room.customer.name}</h5>
                              <span className="text-muted extra-small ms-2">
                                {
                                  (room.latest_msg_time >= room.latest_rep_time)
                                    ?
                                    moment(room.latest_msg_time * 1000).format('YYYY-MM-DD HH:mm')
                                    :
                                    moment(room.latest_rep_time * 1000).format('YYYY-MM-DD HH:mm')
                                }
                              </span>
                            </div>

                            <div className="d-flex align-items-center">
                              <div className="line-clamp me-auto">
                                {
                                  (room.latest_msg_time >= room.latest_rep_time)
                                    ?
                                    room.latest_msg_show
                                    :
                                    room.latest_rep_show
                                }
                              </div>
                              {
                                room.unread !== undefined && room.unread > 0
                                  ?
                                  <div className="badge badge-circle bg-primary ms-5">
                                    <span>{room.unread}</span>
                                  </div>
                                  :
                                  <></>
                              }
                            </div>
                          </div>
                          <div
                            className="col-auto"
                            style={{ cursor: 'pointer' }}
                          >
                            <div className="dropdown">
                              <a className="icon text-muted" href="#" role="button" data-bs-toggle="dropdown"
                                 aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="feather feather-more-vertical">
                                  <circle cx="12" cy="12" r="1"></circle>
                                  <circle cx="12" cy="5" r="1"></circle>
                                  <circle cx="12" cy="19" r="1"></circle>
                                </svg>
                              </a>

                              <ul className="dropdown-menu">
                                {/*<li>*/}
                                {/*  <a*/}
                                {/*    className="dropdown-item"*/}
                                {/*    href="#"*/}
                                {/*    data-bs-toggle="modal"*/}
                                {/*    data-bs-target={"#modal-profile-user"}*/}
                                {/*  >*/}
                                {/*    客户详情*/}
                                {/*  </a>*/}
                                {/*  <span*/}
                                {/*    className="dropdown-item"*/}
                                {/*    style={{cursor: "pointer"}}*/}
                                {/*    onClick={() => upgradeService(room.servicer.code, room.customer.code)}*/}
                                {/*  >*/}
                                {/*    升级服务*/}
                                {/*  </span>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*  <hr className="dropdown-divider"/>*/}
                                {/*</li>*/}
                                <li>
                                  {
                                    (
                                      room.status_room === 6
                                        ?
                                        <span
                                          className="dropdown-item text-muted"
                                          style={{cursor: "pointer"}}
                                        >
                                          结束会话
                                        </span>
                                        :
                                        <span
                                          className="dropdown-item text-danger"
                                          style={{cursor: "pointer"}}
                                          onClick={() => setServiceState(room.servicer.code, room.customer.code)}
                                        >
                                          结束会话
                                        </span>
                                    )
                                  }
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ))}

              {servicers.length === 0 &&
                <>
                  <div className="card border-0 text-reset" style={{cursor: 'pointer'}}>
                    <div className="card-body">
                      <div className="row gx-5">
                        <div className="col-auto">
                          <div className="avatar">
                            <svg className="avatar-img placeholder-img" width="100%" height="100%"
                                 xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder"
                                 preserveAspectRatio="xMidYMid slice" focusable="false">
                              <title>Placeholder</title>
                              <rect width="100%" height="100%" fill="#868e96"></rect>
                            </svg>
                          </div>
                        </div>

                        <div className="col">
                          <div className="d-flex align-items-center mb-3">
                            <h5 className="placeholder-glow w-100 mb-0">
                              <span className="placeholder col-5"></span>
                            </h5>
                          </div>

                          <div className="placeholder-glow">
                            <span className="placeholder col-12"></span>
                            <span className="placeholder col-8"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card border-0 text-reset" style={{cursor: 'pointer'}}>
                    <div className="card-body">
                      <div className="row gx-5">
                        <div className="col-auto">
                          <div className="avatar">
                            <svg className="avatar-img placeholder-img" width="100%" height="100%"
                                 xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder"
                                 preserveAspectRatio="xMidYMid slice" focusable="false">
                              <title>Placeholder</title>
                              <rect width="100%" height="100%" fill="#868e96"></rect>
                            </svg>
                          </div>
                        </div>

                        <div className="col">
                          <div className="d-flex align-items-center mb-3">
                            <h5 className="placeholder-glow  w-100  mb-0">
                              <span className="placeholder col-5"></span>
                            </h5>
                          </div>

                          <div className="placeholder-glow">
                            <span className="placeholder col-12"></span>
                            <span className="placeholder col-8"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default RoomAside;
